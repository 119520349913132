import React, { useRef } from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import ServicesHero from "../modules/hero/services-hero";
import FooterMapDetails from "../components/footer/footer-map-details";
import ModuleService from "../modules/module-service";
import Aassociate from "../images/services/Case-Studies-Aassociate.jpeg";
import AVNT from "../images/services/Case-Studies-AVNT.jpeg";
import Blitz from "../images/services/Case-Studies-Blitz.jpeg";
import Sricles from "../images/services/Case-Studies-Sircles.jpeg";

const Services = ({ location }) => {
  const pageLayoutRef = useRef();

  const seo = {
    pageUrl: "/services",
    pageTitle: "Services | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Services | Vello | A full-service creative agency.",
  };

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  const featured = [
    {
      title: "associate.com",
      coverImage: Aassociate,
    },
    {
      title: "AVNT",
      coverImage: AVNT,
    },
  ];

  const webFeatured = [
    {
      title: "Sircles",
      coverImage: Sricles,
    },
    {
      title: "Blitz App",
      coverImage: Blitz,
    },
  ];

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ServicesHero
          title={`Our Services.`}
          description={`We build impactful software solutions at the intersection of design, data, and technology.`}
          logoImage={""}
          pageLayoutRef={pageLayoutRef}
        />
        <ModuleService
          title="User Experience & Design"
          slug="user-experience-design"
          featuredWork={featured}
          pageLayoutRef={pageLayoutRef}
        />
        <ModuleService
          title="Web & Mobile Development"
          slug="web-and-mobile-development"
          featuredWork={webFeatured}
          pageLayoutRef={pageLayoutRef}
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default Services;
